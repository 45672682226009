export const appHead = {"meta":[{"name":"viewport","content":"width=device-width, initial-scale=1"},{"charset":"utf-8"}],"link":[{"rel":"icon","type":"image/x-icon","href":"/favicons/favicon.ico"},{"rel":"apple-touch-icon","sizes":"180x180","href":"/favicons/apple-touch-icon.png"},{"rel":"mask-icon","href":"/favicons/safari-pinned-tab.svg"},{"rel":"icon","type":"image/png","sizes":"32x32","href":"/favicons/favicon-32x32.png"},{"rel":"icon","type":"image/png","sizes":"128x128","href":"/favicons/favicon-128x128.png"},{"rel":"icon","type":"image/png","sizes":"152x152","href":"/favicons/favicon-152x152.png"},{"rel":"icon","type":"image/png","sizes":"167x167","href":"/favicons/favicon-167x167.png"},{"rel":"icon","type":"image/png","sizes":"180x180","href":"/favicons/favicon-180x180.png"},{"rel":"icon","type":"image/png","sizes":"196x196","href":"/favicons/favicon-196x196.png"},{"rel":"icon","type":"image/png","sizes":"16x16","href":"/favicons/favicon-16x16.png"},{"rel":"manifest","href":"/favicons/site.webmanifest"},{"rel":"shortcut icon","href":"/favicons/favicon.ico"}],"style":[],"script":[],"noscript":[]}

export const appBaseURL = "/"

export const appBuildAssetsDir = "/_nuxt/"

export const appCdnURL = ""

export const appLayoutTransition = false

export const appPageTransition = false

export const appKeepalive = false

export const appRootId = "__nuxt"

export const appRootTag = "div"

export const renderJsonPayloads = true

export const componentIslands = false

export const payloadExtraction = true

export const appManifest = true

export const remoteComponentIslands = false

export const selectiveClient = false

export const devPagesDir = null

export const devRootDir = null

export const nuxtLinkDefaults = {"componentName":"NuxtLink"}

export const asyncDataDefaults = {"deep":true}

export const fetchDefaults = {}

export const vueAppRootContainer = '#__nuxt'