export const MADAME = 'madame'
export const MISTER = 'mister'

export const civilities = [
  {
    label: 'profile.personalInfos.civility.madam',
    value: MADAME,
    data: MADAME,
  },
  {
    label: 'profile.personalInfos.civility.sir',
    value: MISTER,
    data: MISTER,
  },
]
export const getCivility = (value) =>
  value
    ? civilities.find((civility) => civility.value === value)?.label
    : MADAME
