import { default as indexHtoN0wX3AFMeta } from "/app/pages/[userType]/appointments/index.vue?macro=true";
import { default as index3Af4Mv7XrRMeta } from "/app/pages/[userType]/documents-holder/index.vue?macro=true";
import { default as index2LgUjiWTHfMeta } from "/app/pages/[userType]/index.vue?macro=true";
import { default as indexzZZyf3ZZyEMeta } from "/app/pages/[userType]/my-services/index.vue?macro=true";
import { default as indexnSo5OsNbFhMeta } from "/app/pages/[userType]/online-services/index.vue?macro=true";
import { default as indexjs1iwgawxyMeta } from "/app/pages/[userType]/profile/email-submitted/index.vue?macro=true";
import { default as indexNQLFmldL8FMeta } from "/app/pages/[userType]/profile/email/index.vue?macro=true";
import { default as indexTWjiP7QkzcMeta } from "/app/pages/[userType]/profile/index.vue?macro=true";
import { default as indexuJghIckbWWMeta } from "/app/pages/[userType]/register/index.vue?macro=true";
import { default as indexdr6NG3lgisMeta } from "/app/pages/apis/public/index.vue?macro=true";
import { default as indexnVG6zWPHq8Meta } from "/app/pages/completion/index.vue?macro=true";
import { default as index6GXnyaV9M1Meta } from "/app/pages/cookies/index.vue?macro=true";
import { default as indexEx4o3HISO1Meta } from "/app/pages/forgot-identifier/index.vue?macro=true";
import { default as indexN6pT4Un8hYMeta } from "/app/pages/index.vue?macro=true";
import { default as indexgxDD7ZdoTBMeta } from "/app/pages/legal-notice/index.vue?macro=true";
import { default as indexgK5VicK7y2Meta } from "/app/pages/login/index.vue?macro=true";
import { default as indexhrjhHqUR3YMeta } from "/app/pages/terms-of-use/index.vue?macro=true";
import { default as indexssE8NGQ521Meta } from "/app/pages/use-mconnect/index.vue?macro=true";
export default [
  {
    name: indexHtoN0wX3AFMeta?.name ?? "userType-appointments___fr",
    path: indexHtoN0wX3AFMeta?.path ?? "/:userType/rendez-vous",
    meta: indexHtoN0wX3AFMeta || {},
    alias: indexHtoN0wX3AFMeta?.alias || [],
    redirect: indexHtoN0wX3AFMeta?.redirect || undefined,
    component: () => import("/app/pages/[userType]/appointments/index.vue").then(m => m.default || m)
  },
  {
    name: indexHtoN0wX3AFMeta?.name ?? "userType-appointments___en",
    path: indexHtoN0wX3AFMeta?.path ?? "/en/:userType/appointments",
    meta: indexHtoN0wX3AFMeta || {},
    alias: indexHtoN0wX3AFMeta?.alias || [],
    redirect: indexHtoN0wX3AFMeta?.redirect || undefined,
    component: () => import("/app/pages/[userType]/appointments/index.vue").then(m => m.default || m)
  },
  {
    name: index3Af4Mv7XrRMeta?.name ?? "userType-documents-holder___fr",
    path: index3Af4Mv7XrRMeta?.path ?? "/:userType/porte-documents",
    meta: index3Af4Mv7XrRMeta || {},
    alias: index3Af4Mv7XrRMeta?.alias || [],
    redirect: index3Af4Mv7XrRMeta?.redirect || undefined,
    component: () => import("/app/pages/[userType]/documents-holder/index.vue").then(m => m.default || m)
  },
  {
    name: index3Af4Mv7XrRMeta?.name ?? "userType-documents-holder___en",
    path: index3Af4Mv7XrRMeta?.path ?? "/en/:userType/documents-holder",
    meta: index3Af4Mv7XrRMeta || {},
    alias: index3Af4Mv7XrRMeta?.alias || [],
    redirect: index3Af4Mv7XrRMeta?.redirect || undefined,
    component: () => import("/app/pages/[userType]/documents-holder/index.vue").then(m => m.default || m)
  },
  {
    name: index2LgUjiWTHfMeta?.name ?? "userType___fr",
    path: index2LgUjiWTHfMeta?.path ?? "/:userType",
    meta: index2LgUjiWTHfMeta || {},
    alias: index2LgUjiWTHfMeta?.alias || [],
    redirect: index2LgUjiWTHfMeta?.redirect || undefined,
    component: () => import("/app/pages/[userType]/index.vue").then(m => m.default || m)
  },
  {
    name: index2LgUjiWTHfMeta?.name ?? "userType___en",
    path: index2LgUjiWTHfMeta?.path ?? "/en/:userType",
    meta: index2LgUjiWTHfMeta || {},
    alias: index2LgUjiWTHfMeta?.alias || [],
    redirect: index2LgUjiWTHfMeta?.redirect || undefined,
    component: () => import("/app/pages/[userType]/index.vue").then(m => m.default || m)
  },
  {
    name: indexzZZyf3ZZyEMeta?.name ?? "userType-my-services___fr",
    path: indexzZZyf3ZZyEMeta?.path ?? "/:userType/mes-demarches",
    meta: indexzZZyf3ZZyEMeta || {},
    alias: indexzZZyf3ZZyEMeta?.alias || [],
    redirect: indexzZZyf3ZZyEMeta?.redirect || undefined,
    component: () => import("/app/pages/[userType]/my-services/index.vue").then(m => m.default || m)
  },
  {
    name: indexzZZyf3ZZyEMeta?.name ?? "userType-my-services___en",
    path: indexzZZyf3ZZyEMeta?.path ?? "/en/:userType/my-services",
    meta: indexzZZyf3ZZyEMeta || {},
    alias: indexzZZyf3ZZyEMeta?.alias || [],
    redirect: indexzZZyf3ZZyEMeta?.redirect || undefined,
    component: () => import("/app/pages/[userType]/my-services/index.vue").then(m => m.default || m)
  },
  {
    name: indexnSo5OsNbFhMeta?.name ?? "userType-online-services___fr",
    path: indexnSo5OsNbFhMeta?.path ?? "/:userType/demarches-en-ligne",
    meta: indexnSo5OsNbFhMeta || {},
    alias: indexnSo5OsNbFhMeta?.alias || [],
    redirect: indexnSo5OsNbFhMeta?.redirect || undefined,
    component: () => import("/app/pages/[userType]/online-services/index.vue").then(m => m.default || m)
  },
  {
    name: indexnSo5OsNbFhMeta?.name ?? "userType-online-services___en",
    path: indexnSo5OsNbFhMeta?.path ?? "/en/:userType/online-services",
    meta: indexnSo5OsNbFhMeta || {},
    alias: indexnSo5OsNbFhMeta?.alias || [],
    redirect: indexnSo5OsNbFhMeta?.redirect || undefined,
    component: () => import("/app/pages/[userType]/online-services/index.vue").then(m => m.default || m)
  },
  {
    name: indexjs1iwgawxyMeta?.name ?? "userType-profile-email-submitted___fr",
    path: indexjs1iwgawxyMeta?.path ?? "/:userType/email-envoye",
    meta: indexjs1iwgawxyMeta || {},
    alias: indexjs1iwgawxyMeta?.alias || [],
    redirect: indexjs1iwgawxyMeta?.redirect || undefined,
    component: () => import("/app/pages/[userType]/profile/email-submitted/index.vue").then(m => m.default || m)
  },
  {
    name: indexjs1iwgawxyMeta?.name ?? "userType-profile-email-submitted___en",
    path: indexjs1iwgawxyMeta?.path ?? "/en/:userType/email-submitted",
    meta: indexjs1iwgawxyMeta || {},
    alias: indexjs1iwgawxyMeta?.alias || [],
    redirect: indexjs1iwgawxyMeta?.redirect || undefined,
    component: () => import("/app/pages/[userType]/profile/email-submitted/index.vue").then(m => m.default || m)
  },
  {
    name: indexNQLFmldL8FMeta?.name ?? "userType-profile-email___fr",
    path: indexNQLFmldL8FMeta?.path ?? "/:userType/profil/adresse-electronique",
    meta: indexNQLFmldL8FMeta || {},
    alias: indexNQLFmldL8FMeta?.alias || [],
    redirect: indexNQLFmldL8FMeta?.redirect || undefined,
    component: () => import("/app/pages/[userType]/profile/email/index.vue").then(m => m.default || m)
  },
  {
    name: indexNQLFmldL8FMeta?.name ?? "userType-profile-email___en",
    path: indexNQLFmldL8FMeta?.path ?? "/en/:userType/profile/email",
    meta: indexNQLFmldL8FMeta || {},
    alias: indexNQLFmldL8FMeta?.alias || [],
    redirect: indexNQLFmldL8FMeta?.redirect || undefined,
    component: () => import("/app/pages/[userType]/profile/email/index.vue").then(m => m.default || m)
  },
  {
    name: indexTWjiP7QkzcMeta?.name ?? "userType-profile___fr",
    path: indexTWjiP7QkzcMeta?.path ?? "/:userType/profil",
    meta: indexTWjiP7QkzcMeta || {},
    alias: indexTWjiP7QkzcMeta?.alias || [],
    redirect: indexTWjiP7QkzcMeta?.redirect || undefined,
    component: () => import("/app/pages/[userType]/profile/index.vue").then(m => m.default || m)
  },
  {
    name: indexTWjiP7QkzcMeta?.name ?? "userType-profile___en",
    path: indexTWjiP7QkzcMeta?.path ?? "/en/:userType/profile",
    meta: indexTWjiP7QkzcMeta || {},
    alias: indexTWjiP7QkzcMeta?.alias || [],
    redirect: indexTWjiP7QkzcMeta?.redirect || undefined,
    component: () => import("/app/pages/[userType]/profile/index.vue").then(m => m.default || m)
  },
  {
    name: indexuJghIckbWWMeta?.name ?? "userType-register___fr",
    path: indexuJghIckbWWMeta?.path ?? "/:userType/inscription",
    meta: indexuJghIckbWWMeta || {},
    alias: indexuJghIckbWWMeta?.alias || [],
    redirect: indexuJghIckbWWMeta?.redirect || undefined,
    component: () => import("/app/pages/[userType]/register/index.vue").then(m => m.default || m)
  },
  {
    name: indexuJghIckbWWMeta?.name ?? "userType-register___en",
    path: indexuJghIckbWWMeta?.path ?? "/en/:userType/register",
    meta: indexuJghIckbWWMeta || {},
    alias: indexuJghIckbWWMeta?.alias || [],
    redirect: indexuJghIckbWWMeta?.redirect || undefined,
    component: () => import("/app/pages/[userType]/register/index.vue").then(m => m.default || m)
  },
  {
    name: indexdr6NG3lgisMeta?.name ?? "apis-public___fr",
    path: indexdr6NG3lgisMeta?.path ?? "/apis/public",
    meta: indexdr6NG3lgisMeta || {},
    alias: indexdr6NG3lgisMeta?.alias || [],
    redirect: indexdr6NG3lgisMeta?.redirect || undefined,
    component: () => import("/app/pages/apis/public/index.vue").then(m => m.default || m)
  },
  {
    name: indexdr6NG3lgisMeta?.name ?? "apis-public___en",
    path: indexdr6NG3lgisMeta?.path ?? "/en/apis/public",
    meta: indexdr6NG3lgisMeta || {},
    alias: indexdr6NG3lgisMeta?.alias || [],
    redirect: indexdr6NG3lgisMeta?.redirect || undefined,
    component: () => import("/app/pages/apis/public/index.vue").then(m => m.default || m)
  },
  {
    name: indexnVG6zWPHq8Meta?.name ?? "completion___fr",
    path: indexnVG6zWPHq8Meta?.path ?? "/completion",
    meta: indexnVG6zWPHq8Meta || {},
    alias: indexnVG6zWPHq8Meta?.alias || [],
    redirect: indexnVG6zWPHq8Meta?.redirect || undefined,
    component: () => import("/app/pages/completion/index.vue").then(m => m.default || m)
  },
  {
    name: indexnVG6zWPHq8Meta?.name ?? "completion___en",
    path: indexnVG6zWPHq8Meta?.path ?? "/en/completion",
    meta: indexnVG6zWPHq8Meta || {},
    alias: indexnVG6zWPHq8Meta?.alias || [],
    redirect: indexnVG6zWPHq8Meta?.redirect || undefined,
    component: () => import("/app/pages/completion/index.vue").then(m => m.default || m)
  },
  {
    name: index6GXnyaV9M1Meta?.name ?? "cookies___fr",
    path: index6GXnyaV9M1Meta?.path ?? "/cookies",
    meta: index6GXnyaV9M1Meta || {},
    alias: index6GXnyaV9M1Meta?.alias || [],
    redirect: index6GXnyaV9M1Meta?.redirect || undefined,
    component: () => import("/app/pages/cookies/index.vue").then(m => m.default || m)
  },
  {
    name: index6GXnyaV9M1Meta?.name ?? "cookies___en",
    path: index6GXnyaV9M1Meta?.path ?? "/en/cookies",
    meta: index6GXnyaV9M1Meta || {},
    alias: index6GXnyaV9M1Meta?.alias || [],
    redirect: index6GXnyaV9M1Meta?.redirect || undefined,
    component: () => import("/app/pages/cookies/index.vue").then(m => m.default || m)
  },
  {
    name: indexEx4o3HISO1Meta?.name ?? "forgot-identifier___fr",
    path: indexEx4o3HISO1Meta?.path ?? "/identifiant-oublie",
    meta: indexEx4o3HISO1Meta || {},
    alias: indexEx4o3HISO1Meta?.alias || [],
    redirect: indexEx4o3HISO1Meta?.redirect || undefined,
    component: () => import("/app/pages/forgot-identifier/index.vue").then(m => m.default || m)
  },
  {
    name: indexEx4o3HISO1Meta?.name ?? "forgot-identifier___en",
    path: indexEx4o3HISO1Meta?.path ?? "/en/forgot-identifier",
    meta: indexEx4o3HISO1Meta || {},
    alias: indexEx4o3HISO1Meta?.alias || [],
    redirect: indexEx4o3HISO1Meta?.redirect || undefined,
    component: () => import("/app/pages/forgot-identifier/index.vue").then(m => m.default || m)
  },
  {
    name: indexN6pT4Un8hYMeta?.name ?? "index___fr",
    path: indexN6pT4Un8hYMeta?.path ?? "/",
    meta: indexN6pT4Un8hYMeta || {},
    alias: indexN6pT4Un8hYMeta?.alias || [],
    redirect: indexN6pT4Un8hYMeta?.redirect || undefined,
    component: () => import("/app/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexN6pT4Un8hYMeta?.name ?? "index___en",
    path: indexN6pT4Un8hYMeta?.path ?? "/en",
    meta: indexN6pT4Un8hYMeta || {},
    alias: indexN6pT4Un8hYMeta?.alias || [],
    redirect: indexN6pT4Un8hYMeta?.redirect || undefined,
    component: () => import("/app/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexgxDD7ZdoTBMeta?.name ?? "legal-notice___fr",
    path: indexgxDD7ZdoTBMeta?.path ?? "/mention-legales",
    meta: indexgxDD7ZdoTBMeta || {},
    alias: indexgxDD7ZdoTBMeta?.alias || [],
    redirect: indexgxDD7ZdoTBMeta?.redirect || undefined,
    component: () => import("/app/pages/legal-notice/index.vue").then(m => m.default || m)
  },
  {
    name: indexgxDD7ZdoTBMeta?.name ?? "legal-notice___en",
    path: indexgxDD7ZdoTBMeta?.path ?? "/en/legal-notice",
    meta: indexgxDD7ZdoTBMeta || {},
    alias: indexgxDD7ZdoTBMeta?.alias || [],
    redirect: indexgxDD7ZdoTBMeta?.redirect || undefined,
    component: () => import("/app/pages/legal-notice/index.vue").then(m => m.default || m)
  },
  {
    name: indexgK5VicK7y2Meta?.name ?? "login___fr",
    path: indexgK5VicK7y2Meta?.path ?? "/login",
    meta: indexgK5VicK7y2Meta || {},
    alias: indexgK5VicK7y2Meta?.alias || [],
    redirect: indexgK5VicK7y2Meta?.redirect || undefined,
    component: () => import("/app/pages/login/index.vue").then(m => m.default || m)
  },
  {
    name: indexgK5VicK7y2Meta?.name ?? "login___en",
    path: indexgK5VicK7y2Meta?.path ?? "/en/login",
    meta: indexgK5VicK7y2Meta || {},
    alias: indexgK5VicK7y2Meta?.alias || [],
    redirect: indexgK5VicK7y2Meta?.redirect || undefined,
    component: () => import("/app/pages/login/index.vue").then(m => m.default || m)
  },
  {
    name: indexhrjhHqUR3YMeta?.name ?? "terms-of-use___fr",
    path: indexhrjhHqUR3YMeta?.path ?? "/conditions-generales-d-utilisation",
    meta: indexhrjhHqUR3YMeta || {},
    alias: indexhrjhHqUR3YMeta?.alias || [],
    redirect: indexhrjhHqUR3YMeta?.redirect || undefined,
    component: () => import("/app/pages/terms-of-use/index.vue").then(m => m.default || m)
  },
  {
    name: indexhrjhHqUR3YMeta?.name ?? "terms-of-use___en",
    path: indexhrjhHqUR3YMeta?.path ?? "/en/terms-of-use",
    meta: indexhrjhHqUR3YMeta || {},
    alias: indexhrjhHqUR3YMeta?.alias || [],
    redirect: indexhrjhHqUR3YMeta?.redirect || undefined,
    component: () => import("/app/pages/terms-of-use/index.vue").then(m => m.default || m)
  },
  {
    name: indexssE8NGQ521Meta?.name ?? "use-mconnect___fr",
    path: indexssE8NGQ521Meta?.path ?? "/utiliser-mconnect",
    meta: indexssE8NGQ521Meta || {},
    alias: indexssE8NGQ521Meta?.alias || [],
    redirect: indexssE8NGQ521Meta?.redirect || undefined,
    component: () => import("/app/pages/use-mconnect/index.vue").then(m => m.default || m)
  },
  {
    name: indexssE8NGQ521Meta?.name ?? "use-mconnect___en",
    path: indexssE8NGQ521Meta?.path ?? "/en/use-mconnect",
    meta: indexssE8NGQ521Meta || {},
    alias: indexssE8NGQ521Meta?.alias || [],
    redirect: indexssE8NGQ521Meta?.redirect || undefined,
    component: () => import("/app/pages/use-mconnect/index.vue").then(m => m.default || m)
  }
]